import React, { useRef, useState } from 'react';
import { Form, Input, Button, Steps, Checkbox, InputNumber, Row, Col, message, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const { Step } = Steps;
const { TextArea } = Input;

const servicios = [
    {
        title: "SEO (Posicionamiento en Búsquedas de Google)",
        value: "google_seo"
    },
    {
        title: "Marketing Digital con Meta (Facebook, Pixel)",
        value: "meta_marketing"
    },
    {
        title: "Ecommerce para la Venta de productos o servicios online",
        value: "ecommerce"
    },
    {
        title: "Módulo de Reserva de Citas",
        value: "citas"
    },
    {
        title: "Dominio .com, .org, .mx, etc.",
        value: "dominio"
    },
    {
        title: "Certificado de Seguridad",
        value: "certificado_seguridad"
    },
    {
        title: "Hospedaje Web (Hosting)",
        value: "hosting"
    }
];

const steps = (t) => [
    {
        title: t('Acerca de ti'),
        content: (
            <Row gutter={[16, 12]}>
                <Col span={12}>
                    <Form.Item
                        label={t("Nombre")}
                        name="nombre"
                        rules={[{ required: true, message: t('Por favor, ingrese su nombre!') }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={t("Apellidos")}
                        name="apellidos"
                        rules={[{ required: true, message: t('Por favor, ingrese sus apellidos!') }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={t("Correo")}
                        name="correo"
                        rules={[
                            { required: true, message: t('Por favor, ingrese su correo!') },
                            { type: 'email', message: t('Correo no es válido!') },
                        ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={t("Teléfono")}
                        name="telefono"
                        rules={[{ required: true, message: t('Por favor, ingrese su teléfono!') }]}
                    >
                        <InputNumber
                            size='large'
                            style={{ width: '100%' }}
                            parser={value => value.replace(/\D/g, '')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        ),
    },
    {
        title: t('Tus Intereses'),
        content: (
            <Row gutter={[16, 12]}>
                <Col>
                    <Form.Item
                        label={t("Seleccione los servicios a adquirir")}
                        name="servicios"
                    >
                        <Checkbox.Group>
                            <Row gutter={[16, 12]}>
                                {servicios.map((e, index) => (
                                    <Col sm={24} md={8} key={index}>
                                        <Checkbox value={e.value}>{t(e.title)}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
                <Col sm={12} md={16}>
                    <Form.Item
                        name="dominio"
                        label={t("Dominio o Sitio Web")}
                    >
                        <Input size='large' placeholder={t('Ingresa el URL o el Dominio si dispones de él')} />
                    </Form.Item>
                </Col>
                <Col sm={12} md={8}>
                    <Form.Item
                        name="presupuesto"
                        label={t("Presupuesto")}
                    >
                        <InputNumber
                            size='large'
                            min={0}
                            placeholder="$ 250,00"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        ),
    },
    {
        title: t('Tu Proyecto'),
        content: (
            <Form.Item
                style={{ marginBottom: '3em', width: '100%' }}
                label={t("Describe tu proyecto")}
                name="descripcion_proyecto"
                rules={[{ required: true, message: t('Por favor, describe tu proyecto') }]}
            >
                <TextArea rows={4} />
            </Form.Item>
        ),
    }
];

const MultiStepForm = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [isFinal, setFinal] = useState(false);
    const [current, setCurrent] = useState(0);
    const [formData, setFormData] = useState({
        nombre: "",
        apellidos: "",
        correo: "",
        telefono: "",
        servicios: [],
        dominio: '',
        presupuesto: null,
        descripcion_proyecto: ""
    });

    const handleFormChange = (changedValues) => {
        setFormData(prevData => ({
            ...prevData,
            ...changedValues
        }));
    };

    const submitForm = () => {
        axios.post('https://throwcode.dev:4000/send', formData)
            .then(() => {
                message.success(t("Hemos enviado la solicitud"));
                setFinal(!isFinal);
            })
            .catch(() => message.error(t("Error al enviar la solicitud")));
    };

    const next = () => {
        form.current.validateFields()
            .then(() => setCurrent(current + 1))
            .catch(() => message.error(t('Verifique que esté toda la información')));
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const renderButtons = () => {
        return (
            <Row gutter={[12, 16]}>
                {current !== 0 && (
                    <Col>
                        <Button size='large' onClick={prev}>
                            {t('Anterior')}
                        </Button>
                    </Col>
                )}
                <Col style={{ flex: 1 }}>
                    {current < steps(t).length - 1 ? (
                        <Button size='large' block type="primary" onClick={next} htmlType='button'>
                            {t('Siguiente')}
                        </Button>
                    ) : (
                        <Button size='large' block type="primary" htmlType='submit'>
                            {t('Finalizar')}
                        </Button>
                    )}
                </Col>
            </Row>
        );
    };

    return !isFinal ? (
        <Form
            className='form-contact'
            autoComplete="off"
            onFinish={submitForm}
            ref={form}
            initialValues={formData}
            onValuesChange={handleFormChange}
            layout='vertical'
        >
            <Steps current={current} size='small'>
                {steps(t).map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div style={{ marginTop: '2em' }} className="steps-content">{steps(t)[current].content}</div>
            <div className="steps-action" style={{ marginTop: '1em' }}>
                {renderButtons()}
            </div>
        </Form>
    ) : (
        <Result
            status="success"
            title={t("¡Hemos recibido tu solicitud!")}
            subTitle={t("Hemos recibido tu solicitud, nos pondremos en contacto contigo muy pronto.")}
            extra={[
                <Button type="primary" key="console" onClick={() => setFinal(!isFinal)}>
                    {t("Ir al Inicio")}
                </Button>,
            ]}
        />
    );
};

export default MultiStepForm;
