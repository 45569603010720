/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import { Layout, Typography, Progress, Space, } from "antd"

import LandingPage from "./Components/Public/index"
import { LanguageProvider } from "./Contexts/lang"


import "../src/Styles/Theme/antd-home.css"


export default function App(props) {


  return <LanguageProvider>
    <LandingPage />
  </LanguageProvider>
}
