import React, { useEffect, useState } from "react"
import { Layout, Space, Menu, ConfigProvider, Button, Typography, Progress, Carousel, Card, Statistic, Row, Col, Form, Input } from 'antd'
import { DownOutlined, FacebookFilled, InstagramFilled, MenuOutlined, UpOutlined, WechatFilled, WhatsAppOutlined } from '@ant-design/icons'
import { useWheel } from '@use-gesture/react'
import { t } from "i18next"

const { Header, Footer, Sider, Content, } = Layout
const { Text, Title, Paragraph } = Typography

const Section = (props) => <section {...props}>{props.children}</section>

export default function App({ index, current }) {


    return (
        <Section className="nosotros">
            <img src="/img/hexagon.svg" style={{ position: "absolute", right: 0, top: 0, transform: 'translateX(40%) translateY(-30%)' }} />
            <img src="/img/hexagon.svg" style={{ position: "absolute", left: 0, bottom: 0, transform: 'translateX(-40%) translateY(40%)' }} />
            <Section className="section landing-section">
                <Content className="section-content" >
                    <Row gutter={[44, 0]} className="section-content-row">
                        <Col className="section-3years">
                            <div className="number-title" level={4}><span>3+</span></div>
                            <div className="number-subtitle" level={4}>{t('Años de')} <br className="space" />{t('Experiencia')}</div>
                        </Col>
                        <Col className="section-us">
                            <Title className="number-subtitle" level={3} style={{ paddingBottom: '1em' }}>
                                {t('Somos creadores de tecnología especializada en tus necesidades y las de tu negocio')}
                            </Title>
                            <Row style={{ width: '100%' }} gutter={[16, 36]}>
                                <Col md={24} lg={12}>
                                    <Space className="card-content">
                                        <img width={40} src="/img/us/web.svg" style={{ position: "relative", bottom: 5 }} />
                                        <Title className="card-title" level={4}>{t('Desarollo Web y Sofware')}</Title>
                                    </Space>
                                    <Paragraph className="card-description">{t('Realizamos diseños profesionales de sitios web y software enfocados en tener una identidad de la empresa clara y concisa.')}</Paragraph>
                                </Col>
                                <Col md={24} lg={12}>
                                    <Space className="card-content">
                                        <img width={40} src="/img/us/branding.svg" style={{ position: "relative", bottom: 5 }} />
                                        <Title className="card-title" level={4}>{t('Branding')}</Title>
                                    </Space>
                                    {/* <Title className="card-title" level={4}>Branding</Title> */}
                                    <Paragraph className="card-description">
                                        {t('¿Necesitas tener una identidad de tu negocio?')}
                                        {t('Podemos ofrecer diferentes alternativas para que tu negocio tenga una identidad fácil de identificar.')}
                                    </Paragraph>
                                </Col>
                                <Col md={24} lg={12}>
                                    {/* <Title className="card-title" level={4}>Inteligencia Artificial</Title> */}
                                    <Space className="card-content">
                                        <img width={40} src="/img/us/ia.svg" style={{ position: "relative", bottom: 5 }} />
                                        <Title className="card-title" level={4}>{t('Inteligencia Artificial')}</Title>
                                    </Space>
                                    <Paragraph className="card-description">
                                        {t('¿Quieres automatizar procesos de tu negocio?')}
                                        {t('¡Que mejor que utilizar bots desarollados como ChatGPT o tu propia IA utilizando tecnologías como Keras con Python/Tensorflow o Scala/Tensorflow')}
                                    </Paragraph>
                                </Col>
                                <Col md={24} lg={12}>
                                    {/* <Title className="card-title" level={4}></Title> */}
                                    <Space className="card-content">
                                        <img width={40} src="/img/us/cloud.svg" style={{ position: "relative", bottom: 5 }} />
                                        <Title className="card-title" level={4}>{t('Big Data y Minería de Datos')}</Title>
                                    </Space>
                                    <Paragraph className="card-description">
                                        {t('Utilizamos el análisis estadístico y técnicas especializadas de minería de datos para ofrecer la información de tu negocio en tiempo real de forma intuitiva y optimizada, para ayudarte a ver el estatus real de tu negocio.')}
                                        {/* Utilizamos el análisis estadístico y técnicas especializadas de minería de datos para ofrecer la información de tu negocio en tiempo real de forma intuitiva y optimizada, para ayudarte a ver el estatus real de tu negocio. */}
                                    </Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Section>
        </Section>
    )
}