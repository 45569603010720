// src/Contexts/lang/LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';
import i18n from '../locales/i18n'; // Importar la configuración de i18n

// Crear el contexto
export const LanguageContext = createContext();

// Proveedor del contexto
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('');

  useEffect(() => {
    // Obtener el idioma del sistema
    const systemLanguage = navigator.language.split('-')[0];
    const supportedLanguage = Object.keys(i18n.options.resources).includes(systemLanguage)
      ? systemLanguage
      : 'en'; // Idioma de respaldo

    setLanguage(supportedLanguage);
    i18n.changeLanguage(supportedLanguage);
  }, []);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
