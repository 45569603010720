import React, { useEffect, useState } from "react";

export default function ({fill = "white", ...args}) {
    return <svg width="1169" height="223" {...args} viewBox="0 0 1169 223" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M382.984 81.3595H346.836V175.138H325.019V81.3595H288.87V62.0943H382.984V81.3595Z" fill={fill} />
        <path d="M472.502 175.138H451.163V128.328C451.163 121.854 449.889 117.024 447.342 113.84C444.9 110.655 441.503 109.063 437.15 109.063C434.602 109.063 431.895 109.806 429.028 111.292C426.162 112.778 423.561 114.848 421.225 117.502C418.996 120.049 417.35 123.021 416.289 126.418V175.138H394.95V58.91H416.289V107.153C419.368 101.845 423.561 97.7057 428.869 94.7337C434.177 91.7616 439.963 90.2756 446.227 90.2756C451.641 90.2756 456.047 91.2309 459.444 93.1415C462.841 94.946 465.495 97.4404 467.406 100.625C469.317 103.703 470.644 107.206 471.388 111.133C472.131 114.954 472.502 118.935 472.502 123.074V175.138Z" fill={fill} />
        <path d="M540.741 110.178C534.265 110.178 528.479 111.451 523.384 113.999C518.288 116.44 514.625 119.996 512.396 124.666V175.138H491.057V91.7086H510.644V109.541C513.617 103.809 517.385 99.2979 521.95 96.0074C526.515 92.7169 531.346 90.9125 536.442 90.5941C537.609 90.5941 538.512 90.5941 539.149 90.5941C539.786 90.5941 540.317 90.6471 540.741 90.7533V110.178Z" fill={fill} />
        <path d="M590.088 176.73C583.294 176.73 577.19 175.616 571.775 173.387C566.361 171.051 561.743 167.867 557.921 163.834C554.205 159.8 551.339 155.183 549.322 149.982C547.305 144.781 546.296 139.314 546.296 133.582C546.296 127.745 547.305 122.225 549.322 117.024C551.339 111.823 554.205 107.206 557.921 103.172C561.743 99.1387 566.361 96.0074 571.775 93.7784C577.19 91.4432 583.294 90.2756 590.088 90.2756C596.883 90.2756 602.934 91.4432 608.242 93.7784C613.657 96.0074 618.275 99.1387 622.097 103.172C625.919 107.206 628.785 111.823 630.696 117.024C632.713 122.225 633.722 127.745 633.722 133.582C633.722 139.314 632.713 144.781 630.696 149.982C628.785 155.183 625.919 159.8 622.097 163.834C618.381 167.867 613.816 171.051 608.402 173.387C602.987 175.616 596.883 176.73 590.088 176.73ZM568.272 133.582C568.272 138.465 569.227 142.817 571.138 146.638C573.049 150.353 575.65 153.272 578.941 155.395C582.232 157.518 585.948 158.579 590.088 158.579C594.123 158.579 597.785 157.518 601.076 155.395C604.368 153.166 606.968 150.194 608.879 146.479C610.897 142.658 611.905 138.306 611.905 133.423C611.905 128.647 610.897 124.348 608.879 120.527C606.968 116.706 604.368 113.733 601.076 111.611C597.785 109.488 594.123 108.426 590.088 108.426C585.948 108.426 582.232 109.541 578.941 111.77C575.65 113.893 573.049 116.865 571.138 120.686C569.227 124.401 568.272 128.7 568.272 133.582Z" fill={fill} />
        <path d="M748.835 91.7086H769.059L734.184 175.138H716.349L702.335 139.314L688.64 175.138H670.645L635.93 91.7086H655.995L680.837 154.758L690.551 127.532L676.06 91.8678H693.736L702.335 117.342L711.094 91.8678H728.77L714.278 127.532L724.152 154.758L748.835 91.7086Z" fill={fill} />
        <path d="M773.822 117.82C773.822 110.921 775.043 104.181 777.485 97.5996C780.033 90.9125 783.696 84.8623 788.473 79.4489C793.25 73.9294 799.089 69.5775 805.99 66.3932C812.891 63.1027 820.747 61.4575 829.558 61.4575C839.962 61.4575 848.933 63.7396 856.471 68.3038C864.114 72.868 869.794 78.8121 873.51 86.136L856.63 97.7588C854.719 93.513 852.224 90.1695 849.145 87.7282C846.067 85.1807 842.776 83.4293 839.272 82.474C835.769 81.4126 832.318 80.8819 828.921 80.8819C823.401 80.8819 818.57 81.9964 814.43 84.2254C810.396 86.4544 806.998 89.3734 804.238 92.9823C801.478 96.5912 799.408 100.625 798.028 105.083C796.754 109.541 796.117 113.999 796.117 118.457C796.117 123.446 796.913 128.275 798.505 132.946C800.098 137.51 802.327 141.596 805.194 145.205C808.166 148.708 811.67 151.521 815.704 153.644C819.844 155.66 824.356 156.669 829.24 156.669C832.743 156.669 836.299 156.085 839.909 154.917C843.519 153.75 846.863 151.892 849.941 149.345C853.02 146.797 855.409 143.507 857.108 139.473L875.102 149.823C872.873 155.448 869.21 160.225 864.114 164.152C859.125 168.079 853.498 171.051 847.234 173.068C840.971 175.085 834.76 176.093 828.603 176.093C820.534 176.093 813.156 174.448 806.468 171.158C799.779 167.761 793.993 163.303 789.11 157.783C784.333 152.158 780.564 145.895 777.804 138.996C775.149 131.99 773.822 124.932 773.822 117.82Z" fill={fill} />
        <path d="M925.996 176.73C919.201 176.73 913.097 175.616 907.683 173.387C902.268 171.051 897.65 167.867 893.828 163.834C890.113 159.8 887.246 155.183 885.229 149.982C883.212 144.781 882.204 139.314 882.204 133.582C882.204 127.745 883.212 122.225 885.229 117.024C887.246 111.823 890.113 107.206 893.828 103.172C897.65 99.1387 902.268 96.0074 907.683 93.7784C913.097 91.4432 919.201 90.2756 925.996 90.2756C932.79 90.2756 938.842 91.4432 944.15 93.7784C949.564 96.0074 954.182 99.1387 958.004 103.172C961.826 107.206 964.692 111.823 966.603 117.024C968.621 122.225 969.629 127.745 969.629 133.582C969.629 139.314 968.621 144.781 966.603 149.982C964.692 155.183 961.826 159.8 958.004 163.834C954.288 167.867 949.723 171.051 944.309 173.387C938.895 175.616 932.79 176.73 925.996 176.73ZM904.179 133.582C904.179 138.465 905.135 142.817 907.046 146.638C908.957 150.353 911.558 153.272 914.849 155.395C918.14 157.518 921.856 158.579 925.996 158.579C930.03 158.579 933.693 157.518 936.984 155.395C940.275 153.166 942.876 150.194 944.787 146.479C946.804 142.658 947.812 138.306 947.812 133.423C947.812 128.647 946.804 124.348 944.787 120.527C942.876 116.706 940.275 113.733 936.984 111.611C933.693 109.488 930.03 108.426 925.996 108.426C921.856 108.426 918.14 109.541 914.849 111.77C911.558 113.893 908.957 116.865 907.046 120.686C905.135 124.401 904.179 128.7 904.179 133.582Z" fill={fill} />
        <path d="M978.936 133.423C978.936 125.356 980.529 118.085 983.714 111.611C986.898 105.03 991.304 99.8286 996.931 96.0074C1002.56 92.1862 1008.98 90.2756 1016.2 90.2756C1022.36 90.2756 1027.88 91.8147 1032.76 94.8929C1037.75 97.8649 1041.63 101.686 1044.39 106.356V58.91H1065.72V151.255C1065.72 153.485 1066.1 155.077 1066.84 156.032C1067.58 156.987 1068.86 157.571 1070.66 157.783V175.138C1066.95 175.881 1063.92 176.252 1061.58 176.252C1057.76 176.252 1054.58 175.297 1052.03 173.387C1049.59 171.476 1048.21 168.982 1047.89 165.903L1047.57 160.649C1044.49 165.85 1040.3 169.831 1034.99 172.59C1029.79 175.35 1024.32 176.73 1018.59 176.73C1012.86 176.73 1007.55 175.616 1002.66 173.387C997.886 171.158 993.693 168.079 990.083 164.152C986.58 160.225 983.82 155.66 981.803 150.459C979.892 145.152 978.936 139.473 978.936 133.423ZM1044.39 144.568V124.666C1043.32 121.588 1041.63 118.828 1039.29 116.387C1036.95 113.946 1034.35 112.035 1031.49 110.655C1028.62 109.169 1025.75 108.426 1022.89 108.426C1019.6 108.426 1016.62 109.116 1013.97 110.496C1011.32 111.876 1008.98 113.734 1006.96 116.069C1005.05 118.404 1003.57 121.111 1002.5 124.189C1001.55 127.267 1001.07 130.504 1001.07 133.901C1001.07 137.298 1001.6 140.535 1002.66 143.613C1003.83 146.585 1005.48 149.186 1007.6 151.415C1009.72 153.644 1012.17 155.395 1014.93 156.669C1017.79 157.943 1020.87 158.579 1024.16 158.579C1026.18 158.579 1028.2 158.261 1030.21 157.624C1032.34 156.881 1034.3 155.926 1036.11 154.758C1038.02 153.485 1039.66 151.998 1041.04 150.3C1042.53 148.496 1043.64 146.585 1044.39 144.568Z" fill={fill} />
        <path d="M1125.37 176.73C1118.68 176.73 1112.63 175.616 1107.21 173.387C1101.8 171.051 1097.18 167.92 1093.36 163.993C1089.54 159.959 1086.56 155.395 1084.44 150.3C1082.42 145.099 1081.42 139.633 1081.42 133.901C1081.42 125.94 1083.17 118.669 1086.67 112.088C1090.28 105.507 1095.38 100.253 1101.96 96.3259C1108.65 92.2924 1116.5 90.2756 1125.53 90.2756C1134.66 90.2756 1142.46 92.2924 1148.94 96.3259C1155.41 100.253 1160.35 105.507 1163.74 112.088C1167.25 118.563 1169 125.569 1169 133.105C1169 134.379 1168.95 135.705 1168.84 137.085C1168.73 138.359 1168.63 139.42 1168.52 140.27H1104.03C1104.45 144.515 1105.67 148.23 1107.69 151.415C1109.81 154.599 1112.47 157.04 1115.65 158.739C1118.94 160.331 1122.45 161.127 1126.16 161.127C1130.41 161.127 1134.39 160.119 1138.11 158.102C1141.93 155.979 1144.53 153.219 1145.91 149.823L1164.22 154.917C1162.21 159.163 1159.29 162.931 1155.46 166.222C1151.75 169.512 1147.34 172.113 1142.25 174.023C1137.15 175.828 1131.52 176.73 1125.37 176.73ZM1103.55 126.736H1147.18C1146.76 122.49 1145.54 118.828 1143.52 115.75C1141.61 112.566 1139.06 110.125 1135.88 108.426C1132.69 106.622 1129.14 105.72 1125.21 105.72C1121.39 105.72 1117.88 106.622 1114.7 108.426C1111.62 110.125 1109.07 112.566 1107.05 115.75C1105.14 118.828 1103.97 122.49 1103.55 126.736Z" fill={fill} />
        <path d="M38.6946 191.792C38.6984 160.713 38.7012 129.633 38.705 98.5526C25.8036 91.0873 12.9023 83.622 0 76.1566V59.8494C31.0389 77.8095 62.0769 95.7695 93.1159 113.73V130.037C80.16 122.541 67.2061 115.044 54.2503 107.548C54.2474 138.628 54.2437 169.708 54.2409 200.788C49.0582 197.789 43.8764 194.791 38.6946 191.792Z" fill={fill} />
        <path fillRule="evenodd" clipRule="evenodd" d="M87.0547 0.00263602C95.7849 0.00136838 104.515 0.000507056 113.245 0C135.527 13.0584 157.81 26.1168 180.092 39.1752C180.046 44.2591 180.002 49.3431 179.96 54.427C153.36 70.1193 126.76 85.8116 100.16 101.504C68.9306 83.4214 37.7012 65.3388 6.47142 47.2565C33.3329 31.5052 60.1937 15.7539 87.0547 0.00263602ZM104.034 12.8703C100.199 12.9129 96.3629 12.9555 92.5273 12.9981C73.6412 24.0243 54.755 35.0504 35.8689 46.0765C57.2804 58.4969 78.692 70.9174 100.104 83.3377C118.833 72.2996 137.563 61.2614 156.292 50.2233C156.296 47.9653 156.301 45.7073 156.306 43.4494C138.949 33.2955 121.592 23.1417 104.235 12.9878C104.168 12.9487 104.101 12.9095 104.034 12.8703Z" fill={fill} />
        <path d="M107.499 223C107.503 191.863 107.507 160.727 107.509 129.589C107.509 124.144 107.509 118.699 107.509 113.254C138.444 95.134 169.38 77.0138 200.315 58.8935V75.2286C174.532 90.2494 148.751 105.27 122.969 120.291V195.631C148.751 180.529 174.532 165.428 200.315 150.326C200.315 156.429 200.315 162.531 200.315 168.634C169.376 186.756 138.438 204.878 107.499 223Z" fill={fill} />
    </svg>

}