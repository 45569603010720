import React from "react";
import { Layout, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Formulario from './Formulario';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Section = (props) => <section {...props}>{props.children}</section>;

export default function App({ index, current }) {
    const { t } = useTranslation();

    return (
        <Section className="contacto">
            <Section className="section landing-section">
                <Content className="section-content">
                    <Row md={24} gutter={[24, 0]} style={{ width: "100%" }}>
                        <Col lg={8}>
                            <Title>{t('Contacto')}</Title>
                            <Paragraph className="description">{t('Llámanos para adquirir más información')}</Paragraph>
                            <Row>
                                <Paragraph className="description">
                                    <a href="tel:+526631015160">
                                        +52 (663) 101 51 60
                                    </a>
                                </Paragraph>
                            </Row>
                            <Row>
                                <Paragraph className="description">
                                    <a href="mailto:admin@throwcode.com">
                                        contacto@throwcode.com
                                    </a>
                                </Paragraph>
                            </Row>
                        </Col>
                        <Col sm={24} md={24} lg={16}>
                            <Formulario />
                        </Col>
                    </Row>
                </Content>
            </Section>
        </Section>
    );
}
