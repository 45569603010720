import React, { useEffect, useState } from "react";
import { Layout, Space, Menu, ConfigProvider, Button, Typography, Progress, Carousel, Card, Statistic, Row, Col, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined, FacebookFilled, InstagramFilled, MenuOutlined, UpOutlined, WechatFilled, WhatsAppOutlined } from '@ant-design/icons';

const { Header, Footer, Sider, Content } = Layout;
const { Text, Title, Paragraph } = Typography;

class AsNavFor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        const { t } = this.props; // Se pasa t como prop desde el componente principal

        const sections = [
            {
                img: <img className="image-main" src='/img/logos-tech/wordpress.svg' style={{ position: "relative", top: 12 }} />,
                description: (
                    <Text className="description">
                        <img className="image-content" src='/img/logos-tech/wordpress.svg' />
                        {t('Para la realización de sitios específicos de índole general, utilizamos la tecnología de WordPress para la creación y desarrollo de sitios web, publicaciones y contenido en general.')}
                    </Text>
                )
            },
            {
                img: <img src='/img/logos-tech/react.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
                description: (
                    <Text className="description">
                        <img className="image-content" src='/img/logos-tech/react.svg' />
                        {t('En el desarrollo de software especializado, utilizamos la librería de React.js para la creación de interfaces, siempre aplicando técnicas de UI/UX (User Interface / User Experience) para el diseño y desarrollo de interfaces.')}
                    </Text>
                )
            },
            {
                img: <img src='/img/logos-tech/express.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
                description: (
                    <Text className="description">
                        <img className="image-content" src='/img/logos-tech/express.svg' />
                        {t('Para la lógica del servidor, almacenamiento de información y backend en general, utilizamos Node.js junto con el framework Express. La versatilidad y escalabilidad de estas tecnologías las hacen óptimas para el desarrollo de software web.')}
                    </Text>
                )
            },
            {
                img: <img src='/img/logos-tech/javaspring.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
                description: (
                    <Text className="description">
                        <img className="image-content" src='/img/logos-tech/javaspring.svg' />
                        {t('¿Tienes un despacho contable? ¿La seguridad es un aspecto fundamental en tu empresa? Considerando estos aspectos, utilizamos Spring para la creación de software financiero, bancario y fiscal. Spring ofrece las herramientas necesarias para proteger tus datos e información.')}
                    </Text>
                )
            },
            {
                img: <img src='/img/logos-tech/mysql.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
                description: (
                    <Text className="description">
                        <img className="image-content" src='/img/logos-tech/mysql.svg' />
                        {t('Utilizamos MySQL para nuestras implementaciones de bases de datos. MySQL es un sistema de gestión de bases de datos relacional ampliamente reconocido, conocido por su fiabilidad y eficiencia. Proporciona las herramientas esenciales para garantizar transacciones seguras y operaciones rápidas, manteniendo la coherencia y la seguridad de la información a lo largo del tiempo.')}
                    </Text>
                )
            },
            {
                img: <img src='/img/logos-tech/mongodb.svg' className="image-main" style={{ position: "relative", top: 8 }} />,
                description: (
                    <Text className="description">
                        <img className="image-content" src='/img/logos-tech/mongodb.svg' />
                        {t('Usamos MongoDB para nuestras soluciones de gestión de bases de datos. MongoDB es una base de datos NoSQL líder en su categoría, diseñada para proporcionar alta disponibilidad, escalabilidad y flexibilidad.')}
                    </Text>
                )
            }
        ];

        return (
            <div style={{ width: "100%" }}>
                <Carousel
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    arrows={false}
                    adaptiveHeight={true}
                    slidesToShow={1}
                    swipeToSlide={true}
                    dots={false}
                >
                    {sections.map(({ img, description }, index) => (
                        <Card key={index} size="small" className="card-tech">
                            {description}
                        </Card>
                    ))}
                </Carousel>
                <div style={{ marginTop: '1em' }} />
                <Carousel
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={5}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    dots={false}
                    responsive={[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        }
                    ]}
                >
                    {sections.map(({ img }, index) => (
                        <div key={index} className="tech-content">
                            <Card className="card-tech" size="small">{img}</Card>
                        </div>
                    ))}
                </Carousel>
            </div>
        );
    }
}

const Section = (props) => <section {...props}>{props.children}</section>;

export default function App({ index, current }) {
    const { t } = useTranslation();

    return (
        <Section className="servicios">
            <img src="/img/hexagon_tiny.svg" style={{ position: "absolute", right: 0, top: 0, transform: 'translateX(40%) translateY(-30%)' }} />
            <img src="/img/hexagon_middle.svg" style={{ position: "absolute", left: 0, bottom: 0, transform: 'translateX(-40%) translateY(40%)' }} />
            <Section className="section landing-section">
                <Content className="section-content">
                    <Row gutter={[44, 0]}>
                        <Col xs={0} sm={0} md={0} lg={8}>
                            <img className="img-content" src="/img/presentation.png" />
                        </Col>
                        <Col md={24} lg={16}>
                            <Title level={3} className="servicios-title white">{t('Utilizamos las tecnologías top en innovación y seguridad')}</Title>
                            <Paragraph className="servicios-subtitle description white">
                                {t('En nuestros desarrollos y proyectos buscamos siempre la mejor calidad posible utilizando las mejores tecnologías que ofrece el mercado, siempre en pro de la innovación, seguridad y facilidad de uso.')}
                            </Paragraph>
                            <AsNavFor t={t} />
                        </Col>
                    </Row>
                </Content>
            </Section>
        </Section>
    );
}
